/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  FormControlLabel, Grid, MenuItem, Switch, TextField,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import * as thunks from '../../store/thunks';
import { CustomButton, PageWrapper } from '../../assets/styled';

function SingleBlog() {
  const params = useParams();
  const blogID = params?.id;

  const dispatch = useDispatch();
  const getBlogsService = useCallback(() => dispatch(thunks.getBlogs()), [dispatch]);
  const updateBlogsService = useCallback(data => dispatch(thunks.updateBlogs({ id: blogID, data })), [dispatch]);

  const blogsData = useSelector(state1 => state1.blogs);
  const allLanguages = useSelector(state1 => state1.allLanguages);

  const [blog, setBlog] = useState('');
  const [title, setTitle] = useState('');
  const [keywords, setKeyWords] = useState('');
  const [img, setImg] = useState('');
  const [content, setContent] = useState('');
  const [isRemoved, setIsRemoved] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    if (blogsData.length === 0) {
      getBlogsService();
    } else {
      const data = blogsData.find(item => item.id === Number(blogID));
      if (!data) return;
      data.keywords = data?.keywords?.join(', ') || '';
      setBlog(data);
      setTitle(data.title);
      setKeyWords(data.keywords);
      setContent(data.content);
      setImg(data.image_url);
      setIsRemoved(data.is_removed);
      setSelectedLanguage(data?.relatedLanguage);
    }
  }, [blogID, blogsData, getBlogsService]);

  const updateBlog = () => {
    const keys = [];
    keywords.split(',').forEach((item) => {
      keys.push(item.split(' ').join(''));
    });
    const data = {
      title,
      image_url: img,
      keywords: keys || [],
      content: content.toString('html'),
      is_removed: isRemoved,
      relatedLanguage: selectedLanguage,
    };
    updateBlogsService(data);
  };

  useEffect(() => {
    dispatch(thunks.getLanguages());
  }, [dispatch]);

  return (
    <PageWrapper>
      {
        title && (
          <Grid container spacing="3">
            <Grid item lg={4} xs={12}>
              <TextField
                label="Blog Başlığı"
                fullWidth
                variant="standard"
                value={title}
                InputLabelProps={{ shrink: true }}
                onChange={e => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={!isRemoved}
                    onChange={() => setIsRemoved(!isRemoved)}
                    name="isActive"
                    color="primary"
                  />
                )}
                label={isRemoved ? 'Pasif' : 'Yayında'}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                label="Görsel"
                fullWidth
                variant="standard"
                placeholder="Görsel"
                value={img}
                onChange={e => setImg(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                label="Key Words"
                fullWidth
                variant="standard"
                placeholder="Virgül ile ayırınız. Örnek: İngilizce, Dil öğrenme, hafıza teknikleri gibi"
                value={keywords}
                onChange={e => setKeyWords(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={4} xs={12} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <TextField
                select
                fullWidth
                del
                variant="standard"
                label="İlgili Diller"
                value={selectedLanguage}
                onChange={e => setSelectedLanguage(e.target.value)}
              >
                {allLanguages?.filter(pl => !pl.is_removed).map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </TextField>
              <CustomButton onClick={() => setSelectedLanguage(null)}>Seçilen Dili Temizle</CustomButton>
            </Grid>
            <Grid item lg={12} xs={12}>
              <p style={{ color: 'white' }}>Link: {blog.link}</p>
            </Grid>
            <Grid item lg={12} xs={12} style={{ background: 'white' }}>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                formats={[
                  'header',
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image',
                  'color',
                ]}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <CustomButton component="label" onClick={() => updateBlog()}>Düzenlemeyi Kaydet</CustomButton>
            </Grid>
          </Grid>
        )
      }
    </PageWrapper>
  );
}

export default SingleBlog;
