/* eslint-disable object-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, PaymentPage } from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import BulkSalesModal from '../components/modals/BulkSalesModal';

function BulkSales() {
  const dispatch = useDispatch();
  const getBulkSales = useCallback(() => dispatch(thunks.getBulkSales()), [dispatch]);
  const data = useSelector(state => state.bulkSales);

  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    getBulkSales();
  }, [getBulkSales]);

  const columns = [
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'User',
      text: 'Kullanıcı',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{`${value?.name} - ${value?.mail}`}</p>
      ),
    },
    {
      name: 'Payment',
      text: 'Ödeme Tipi',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value?.price)} - {value?.payment_type}</p>
      ),
    },
    {
      name: 'UserPackage',
      text: 'Paket',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{value?.Package?.name}</p>
      ),
    },
    {
      name: 'user_count',
      text: 'Kullanıcı Sayısı',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{value}</p>
      ),
    },
    {
      name: 'Codes',
      text: 'Kullanılan Kod',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{value?.filter?.(c => c.user_id)?.length}</p>
      ),
    },
  ];


  return (
    <PaymentPage>
      <Grid container>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
          <h3>Toplu Satışlar</h3>
          <CustomButton purple onClick={() => setOpenModal(true)}>Toplu Satış Ekle</CustomButton>
        </div>
        <Grid item lg={12} xs={12}>
          <Table
            data={data || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: false,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openModal && (
          <BulkSalesModal
            open={openModal}
            onClose={() => setOpenModal(false)}
          />
        )
      }
    </PaymentPage>
  );
}

export default BulkSales;
