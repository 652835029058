import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  // Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

export default function NewCategory(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [name, setName] = useState('');
  const [langId, setLangId] = useState(props.activeLangId || '');
  const [parentId, setParentId] = useState('');
  const [demo, setDemo] = useState(false);
  const [main, setMain] = useState(true);

  const datas = {
    name,
    lang_id: langId,
    // parent_id: parentId,
    is_demo: demo,
  };

  if (main && datas?.parent_id) {
    delete datas.parent_id;
  } else if (!main) {
    datas.parent_id = parseInt(parentId, 10);
  }

  // Redux Store Verisi çağırma
  const allLanguages = useSelector(state1 => state1.allLanguages);
  const category = useSelector(state1 => state1.category);

  const dispatch = useDispatch();
  const createCategory = useCallback(() => dispatch(thunks.createCategory(datas)), [dispatch, datas]);
  const editCategory = useCallback(() => dispatch(thunks.editCategory(datas, props.categoryData?.id)), [dispatch, datas, props.categoryData?.id]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  useEffect(() => {
    if (!allLanguages?.length > 0) {
      dispatch(thunks.getLanguages());
    }
  }, [dispatch, allLanguages]);

  useEffect(() => {
    if (props.categoryData) {
      setMain(props.categoryData?.parent_id === props.categoryData?.id);
    }
  }, [dispatch, props.categoryData]);

  useEffect(() => {
    dispatch(thunks.getCategory(langId));
  }, [dispatch, langId]);

  useEffect(() => {
    if (props.edit && props.categoryData && !name && !parentId && !demo) {
      setName(props.categoryData?.name);
      setLangId(props.categoryData?.lang_id);
      setParentId(props.categoryData?.parent_id);
      setDemo(props.categoryData?.is_demo);
    }
  }, [props.categoryData?.name, props.categoryData?.lang_id, props.categoryData?.parent_id, props.categoryData?.is_demo, props.categoryData]);

  function saveFunc() {
    if (name?.length > 0 && langId) {
      setOpenInfoModal(true);
    } else {
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (name && langId && e.key === 'Enter') {
      saveFunc();
    }
  }

  function runService() {
    if (props.edit) {
      editCategory();
    } else {
      createCategory();
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
        <div>
          <div className="header">
            <h1>{props.edit ? 'Kategoriyi Düzenle' : 'Yeni Kategori Ekle'}</h1>
            <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
          </div>
          <div className="content">
            <div className="form">
              <TextField
                label="Kategori İsmi"
                fullWidth
                variant="standard"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                select
                fullWidth
                variant="standard"
                label="Dil"
                value={langId}
                onChange={e => setLangId(e.target.value)}
                disabled
              >
                {allLanguages?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                control={(
                  <Switch
                    checked={main}
                    onChange={() => { setMain(!main); if (!main) { setParentId(''); } }}
                    name="ismain"
                    color="primary"
                  />
                )}
                label={main ? 'Ana Kategori' : 'Alt Kategori'}
              />
              <TextField
                select
                label="Ana Kateg."
                fullWidth
                variant="standard"
                value={parentId}
                onChange={(e) => { setParentId(e.target.value); }}
                disabled={main}
              >
                {category?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                control={(
                  <Switch
                    checked={demo}
                    onChange={() => setDemo(!demo)}
                    name="isDemo"
                    color="primary"
                  />
                )}
                label={demo ? 'Demo' : 'Demo değil'}
              />
              <div className="buttonWrapper">
                <CustomButton onClick={() => props.onClose()} lined>
                  Vazgeç
                </CustomButton>
                <CustomButton purple onClick={() => saveFunc()}>
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
          {
            openInfoModal && (
              <Info
                open={openInfoModal}
                type="Warning"
                title={props.edit ? 'Kategori Düzenleniyor' : 'Yeni Kategori Ekleniyor'}
                text="Kategoriler kısmından tekrar düzenleyebilirsiniz."
                cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                confirmButton={(<CustomButton purple onClick={() => { runService(); props.onClose(); setOpenInfoModal(false); }}>Tamam</CustomButton>)}
              />
            )
          }
        </div>
      </CustomFade>
    </Modal>
  );
}

NewCategory.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // selectedCategoryId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  categoryData: PropTypes.object,
  activeLangId: PropTypes.number.isRequired,
};

NewCategory.defaultProps = {
  edit: false,
  categoryData: {},
};
