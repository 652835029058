import React, { useState } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import {
  AddUser,
  ArrowRight,
} from '../assets/icons';
import {
  CustomButton,
  PageWrapper,
  SubSideMenu,
  SubSideMenuItem,
} from '../assets/styled';

const selectSortingData = [
  {
    value: '1',
    label: 'Alfabetik',
    sorting: 'name',
  },
  {
    value: '2',
    label: 'En Yüksek Puan',
    sorting: 'points',
  },
  {
    value: '3',
    label: 'En Fazla Öneri',
    sorting: 'suggestions',
  },
  {
    value: '4',
    label: 'En Fazla Onaylanmış',
    sorting: 'approved',
  },
];


function Admin() {
  const [sorting, setSorting] = useState('2');
  const [activeMenu, setActiveMenu] = useState(5);
  // const [title, setTitle] = useState('');
  // const [processOffiecer, setProcessOffiecer] = useState('');
  // const [awardName, setAwardName] = useState('');
  // const [awardPointLimit, setAwardPointLimit] = useState('');
  // const [winnerAmount, setWinnerAmount] = useState('');
  // const [pointPerSuggestion, setPointPerSuggestion] = useState('');
  // const [awardDefinition, setAwardDefinition] = useState('');
  // const [awardStartDate, setAwardStartDate] = useState(null);
  // const [awardEndDate, setAwardEndDate] = useState(null);

  // Fonksiyon Direkt Çalışacaksa
  // useEffect(() => {
  //   dispatch(thunks.doLogin(email, password));
  // }, [dispatch, email, password]);


  // const sortingMethod = selectSortingData.find(s => s.value === sorting).sorting;
  // const sortedUsers = sorting === '1' ? users.sort((a, b) => (`${a?.[sortingMethod]}`).localeCompare(b?.[sortingMethod])) : users.sort((a, b) => b?.[sortingMethod] - a?.[sortingMethod]);
  // const sortedStatics = statics.sort((a, b) => a?.[sortingMethod === 'name' ? 'points' : sortingMethod] - b?.[sortingMethod === 'name' ? 'points' : sortingMethod]);

  // const sortedPlacementData = placementData?.sort((a, b) => b?.points - a?.points);
  // const slicedPlacementData = sortedPlacementData?.slice(eventEnd ? 1 : 0, 5);


  // useEffect(() => {
  //   if (setSortedUsersData !== sortedUsers) {
  //     switch (sorting) {
  //       case '1':
  //         setSortedUsersData(sortedUsers);
  //         break;
  //       case '2':
  //         setSortedUsersData(sortedUsers);
  //         break;
  //       case '3':
  //         setSortedUsersData(sortedUsers);
  //         break;
  //       case '4':
  //         setSortedUsersData(sortedUsers);
  //         break;
  //       default:
  //         setSortedUsersData(sortedUsers);
  //         break;
  //     }
  //   }
  // }, [sorting, sortedUsers]);


  return (
    <PageWrapper style={{ paddingLeft: 0, paddingTop: 0 }}>
      <div style={{ display: 'flex' }}>
        <SubSideMenu>
          <div className="fixed">
            <h2>Genel Yönetim Ayarları</h2>
            <SubSideMenuItem active={activeMenu === 1} onClick={() => setActiveMenu(1)}>
              <p>Kullanıcılar</p>
              <ArrowRight />
            </SubSideMenuItem>
            <SubSideMenuItem active={activeMenu === 2} onClick={() => setActiveMenu(2)}>
              <p>Katagori İşlemleri</p>
              <ArrowRight />
            </SubSideMenuItem>
            <SubSideMenuItem active={activeMenu === 3} onClick={() => setActiveMenu(3)}>
              <p>Şirket Ayarları</p>
              <ArrowRight />
            </SubSideMenuItem>
            <SubSideMenuItem active={activeMenu === 4} onClick={() => setActiveMenu(4)}>
              <p>Sistem Ayarları</p>
              <ArrowRight />
            </SubSideMenuItem>
            <SubSideMenuItem active={activeMenu === 5} onClick={() => setActiveMenu(5)}>
              <p>Ödül Tanımlama</p>
              <ArrowRight />
            </SubSideMenuItem>
          </div>
        </SubSideMenu>
        <div style={{ padding: 15, flex: 1 }}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} hidden={activeMenu !== 1}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <TextField
                  id="greyThemeSelect"
                  select
                  variant="outlined"
                  value={sorting}
                  onChange={e => setSorting(e.target.value)}
                >
                  {selectSortingData.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <CustomButton lightBlack startIcon={<AddUser />}>Çalışan Ekle</CustomButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Admin;
