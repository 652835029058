import React from 'react';
import PropTypes from 'prop-types';
import {
  MiddleWidget,
  Simplelink,
  SmallWidget,
} from '../assets/styled';

function Widgets(props) {
  // const [auth] = useState(true);
  // const [state, setState] = React.useState({
  //   checkedA: true,
  // });

  return (
    <>
      {
        props.type === 'sm' ? (
          <SmallWidget>
            <div className="content">
              <h6>{props.title}</h6>
              <h5>{props.numberData}</h5>
            </div>
          </SmallWidget>
        ) : props.type === 'md' && (
          <MiddleWidget>
            <div className="header">
              <h5>{props.title}</h5>
              <Simplelink to={props.seeAllLink}>Tümünü Gör</Simplelink>
            </div>
            <div className="content">
              idea
            </div>
          </MiddleWidget>
        )
      }
    </>
  );
}

Widgets.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  seeAllLink: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  numberData: PropTypes.number.isRequired,
};

Widgets.defaultProps = {
  // button: false,
};

export default Widgets;
