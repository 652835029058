import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid, MenuItem, TextField, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MoreVert } from '@material-ui/icons';
import {
  CustomIconButton,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PaymentPage, TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import { dateTool } from '../commons/utils';
import { Edit } from '../assets/icons';
import { bugTypes } from '../commons/constants';
import ChangeBugStatus from '../components/modals/ChangeBugStatus';

function BugReports() {
  const [state, setState] = React.useState({
    // searchFilter: '',
  });

  const [bugType, setBugType] = useState('ALL');
  const [isFixed, setIsFixed] = useState('ALL');
  const [changeData, setChangeData] = useState(null);

  const data = useSelector(state1 => state1.wordReports);

  const dispatch = useDispatch();

  const getReports = useCallback(body => dispatch(thunks.getWordReports(body)), [dispatch]);

  useEffect(() => {
    let fixValue = isFixed;
    if (isFixed !== 'ALL') {
      fixValue = Boolean(parseInt(isFixed, 10));
    }
    getReports({ type: bugType, isFixed: fixValue });
  }, [getReports, bugType, isFixed]);


  const columns = [
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'isFixed',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 3 : 2}>
          {value ? 'Çözüldü' : 'Çözülmedi'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'word_id',
      text: 'Hatalı Kelime',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: (value, columnName, index, row) => (
        <p>{value} - {row.Word?.Language?.name}</p>
      ),
    },
    // {
    //   name: 'Word',
    //   text: 'Dil',
    //   filterable: true,
    //   editable: false,
    //   align: 'center',
    //   sortable: true,
    //   type: 'text',
    //   customBody: (value, columnName, index, row) => (
    //     <p>{value?.Language?.name}</p>
    //   ),
    // },
    {
      name: 'type',
      text: 'Hata',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <p>{bugTypes.find(b => b.id === value)?.name}</p>
      ),
    },
    {
      name: 'text',
      text: 'Açıklama',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'user_id',
      text: 'Kullanıcı',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: (value, columnName, index, row) => (
        <Tooltip placement="top" title={`${value} - ${row.User?.mail}`}>
          <span>{row.User?.name}</span>
        </Tooltip>
      ),
    },
    {
      name: 'createdAt',
      text: 'Bildirim Tarihi',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'updatedAt',
      text: 'Çözüm Tarihi',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setChangeData(row); }}>
                  <Edit />Hata Durumunu Değiştir.
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  return (
    <PaymentPage>
      <Grid container>
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
        }}
        >
          <h3>Hata Bildirimleri</h3>
        </div>
        <Grid item lg={12} xs={12}>
          <div className="inputArea">
            <TextField
              select
              label="Bug Tipi"
              fullWidth
              variant="standard"
              value={bugType}
              onChange={(e) => { setBugType(e.target.value); }}
            >
              {bugTypes?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Çözüm Durumu"
              fullWidth
              variant="standard"
              value={isFixed}
              onChange={(e) => { setIsFixed(e.target.value); }}
            >
              <MenuItem key="ALL" value="ALL">Hepsi</MenuItem>
              <MenuItem key="0" value="0">Bekleyen</MenuItem>
              <MenuItem key="1" value="1">Çözülmüş</MenuItem>
            </TextField>
            <p>Toplam: <strong>{data?.length}</strong> kayıt.</p>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={data || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: false,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        changeData && (
          <ChangeBugStatus
            open
            onClose={() => setChangeData(null)}
            data={changeData}
          />

        )
      }
    </PaymentPage>
  );
}

export default BugReports;
