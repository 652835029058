import React, { useCallback, useEffect, useState } from 'react';
import {
  FormControlLabel,
  Grid, InputAdornment, MenuItem, Switch, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel, CheckCircle, Close, MoreVert, Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomIconButton,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import * as actions from '../store/actions';
import { dateTool } from '../commons/utils';
import { Edit, Plus } from '../assets/icons';
import NewCategory from '../components/modals/NewCategory';

function Categories() {
  const [state, setState] = React.useState({
    // searchFilter: '',
  });
  const [activeLangId, setActiveLangId] = useState(1);
  const [activeCatId, setActiveCatId] = useState(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [showSubCat, setShowSubCat] = useState(false);
  const [activeFilter, setActiveFilter] = useState(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [edit, setEdit] = useState(false);

  // Redux Store Verisi çağırma
  const category = useSelector(state1 => state1.category);
  const categorySub = useSelector(state1 => state1.categorySub);
  const allLanguages = useSelector(state1 => state1.allLanguages);

  // Action Listesi
  const dispatch = useDispatch();
  const deleteCategory = useCallback(() => dispatch(thunks.deleteCategory(selectedCategoryId, activeLangId)), [dispatch, selectedCategoryId, activeLangId]);

  // Fonksiyon Direkt Çalışacaksa
  useEffect(() => {
    dispatch(thunks.getCategory(activeLangId));
  }, [dispatch, activeLangId]);

  useEffect(() => {
    if (category?.length > 0) {
      setActiveCatId(category[0]?.id);
    }
  }, [category, activeLangId]);

  useEffect(() => {
    dispatch(actions.setCategorySub([]));
    if (activeCatId) {
      dispatch(thunks.getCategorySub(activeCatId));
    }
  }, [dispatch, activeCatId]);

  useEffect(() => {
    if (!allLanguages?.length > 0) {
      dispatch(thunks.getLanguages());
    }
  }, [dispatch, allLanguages]);

  const columns = [
    {
      name: 'is_removed',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 2 : 3}>
          {value ? 'Pasif' : 'Aktif'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'is_demo',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={!value ? 2 : 3}>
          {!value ? 'Ücretli' : 'Demo'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'name',
      text: 'İsim',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'lang_id',
      text: 'Dil Idsi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'parent_id',
      text: 'Üst Idsi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: (value, asd, asd1, row) => (
        <span>
          {row?.id === value ? 'Ana Kateg.' : value}
        </span>
      ),
    },
    {
      name: 'createdAt',
      text: 'Oluşturma Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'updatedAt',
      text: 'Güncelleme Tarihi',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div>
          {dateTool(value)}
        </div>
      ),
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); setSelectedCategoryId(row.id); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setSelectedCategoryId(null); setEdit(false); setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setEdit(true); setOpenCategoryModal(true); }}
                >
                  <Edit />
                  Kategoriyi Düzenle
                </MobileMoreMenuItem>
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { deleteCategory(); setState({ infoDeleteModal: true, [`anchorMobileTable${row.id}`]: null }); }}
                >
                  {row.is_removed ? <CheckCircle /> : <Cancel />}
                  {row.is_removed ? 'Aktif Yap' : 'Pasif Yap'}
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  let filteredActive = filterType !== 'all' ? (
    (showSubCat ? categorySub : category)?.filter(item => filterType[0] !== 'd' ? item.is_removed?.toString() === filterType : `d${item.is_demo?.toString()}` === filterType)
  ) : (showSubCat ? categorySub : category);
  const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
  const filteredData = searchFilter.length > 0 ? (filteredActive && filteredActive?.filter(item => Object?.keys(item)?.some(key => typeof (item[(key)]) === 'string'
  && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : filteredActive;

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={12} xs={12}>
          <div className="pageHeader">
            <TextField
              label="Ara"
              placeholder="Tablo'da ara"
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                endAdornment: searchFilter?.length > 0 && <InputAdornment position="end"><CustomIconButton noPad onClick={() => setSearchFilter('')}><Close color="primary" /></CustomIconButton></InputAdornment>,
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
            <TextField
              select
              // fullWidth
              variant="standard"
              label="Dil"
              value={activeLangId}
              onChange={e => { setActiveLangId(e.target.value); setShowSubCat(false); }}
            >
              {allLanguages?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={(
                <Switch
                  checked={showSubCat}
                  onChange={() => setShowSubCat(!showSubCat)}
                  name="isSub"
                  color="primary"
                  disabled={!activeCatId}
                />
              )}
              label={!showSubCat ? 'Ana Kateg.' : 'Alt Kateg.'}
            />
            <TextField
              select
              label="Ana Kateg."
              variant="standard"
              value={activeCatId}
              disabled={!showSubCat}
              onChange={e => setActiveCatId(e.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              {category?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <CustomButton purple onClick={() => { setEdit(false); setOpenCategoryModal(true); setSelectedCategoryId(null); }} startIcon={<Plus />}>Kategori Ekle</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <div className="filterButtons">
            <CustomButton orange={activeFilter === 1} purple onClick={() => { setActiveFilter(1); setFilterType('all'); }}>Tümü</CustomButton>
            <CustomButton orange={activeFilter === 2} purple onClick={() => { setActiveFilter(2); setFilterType('false'); }}>Aktif Olan</CustomButton>
            <CustomButton orange={activeFilter === 3} purple onClick={() => { setActiveFilter(3); setFilterType('true'); }}>Pasif Olan</CustomButton>
            <CustomButton orange={activeFilter === 4} purple onClick={() => { setActiveFilter(4); setFilterType('dtrue'); }}>Demo</CustomButton>
            <CustomButton orange={activeFilter === 5} purple onClick={() => { setActiveFilter(5); setFilterType('dfalse'); }}>Demo Değil</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={filteredData || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: true,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openCategoryModal && (
          <NewCategory
            open={openCategoryModal}
            onClose={() => setOpenCategoryModal(false)}
            edit={edit}
            selectedCategoryId={selectedCategoryId}
            activeLangId={activeLangId}
            categoryData={filteredData?.find(select => select.id === selectedCategoryId)}
          />
        )
      }
    </PageWrapper>
  );
}

export default Categories;
