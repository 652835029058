import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Backdrop,
  Modal,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

export default function NewUser(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const datas = {
    name,
    phone,
    mail,
    password,
  };

  const dispatch = useDispatch();
  const addEditUser = useCallback(() => dispatch(thunks.createUser(datas)), [dispatch, datas]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  useEffect(() => {
    if (props.edit && !name && !mail && !password) {
      setName(props.userData?.name);
      setMail(props.userData?.mail);
      setPhone(props.userData?.phone);
      // setPassword(props.userData?.password);
    }
  }, [props.userData, props.edit]);

  function saveFunc() {
    if (name && mail) {
      if (openInfoModal) {
        if (props.edit) {
          addEditUser();
          // useCallback(() => dispatch(thunks.createUser(datas)), [dispatch, datas]);
          dispatch(thunks.createUser(datas, props.selectedUserId));
          props.onClose();
        } else if (password) {
          addEditUser();
          props.onClose();
        } else {
          showToast('Alanları doldurun!', 'warning');
        }
      } else {
        setOpenInfoModal(true);
      }
    } else {
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (e.key === 'Enter') {
      saveFunc();
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
        <div>
          <div className="header">
            <h1>{props.edit ? 'Kullanıcıyı Düzenle' : 'Yeni Kullanıcı Ekle'}</h1>
            <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
          </div>
          <div className="content">
            <div className="form">
              <TextField
                label="İsim"
                fullWidth
                variant="standard"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                label="Telefon"
                fullWidth
                variant="standard"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
              <TextField
                label="Mail"
                fullWidth
                type="email"
                variant="standard"
                value={mail}
                onChange={e => setMail(e.target.value)}
              />
              <TextField
                label="Şifre"
                fullWidth
                type="password"
                variant="standard"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <div className="buttonWrapper">
                <CustomButton onClick={() => props.onClose()} lined>
                  Vazgeç
                </CustomButton>
                <CustomButton purple onClick={() => saveFunc()}>
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
          {
            openInfoModal && (
              <Info
                open={openInfoModal}
                type="Warning"
                title={props.edit ? 'Kullanıcı Düzenleniyor' : 'Yeni Kullanıcı Ekleniyor'}
                text="Kullanıcılar kısmından tekrar düzenleyebilirsiniz."
                cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                confirmButton={(<CustomButton purple onClick={() => saveFunc()}>Tamam</CustomButton>)}
              />
            )
          }
        </div>
      </CustomFade>
    </Modal>
  );
}

NewUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  userData: PropTypes.object,
};

NewUser.defaultProps = {
  edit: false,
  userData: {},
};
