import {
  Button, Fade, FormControl, IconButton, Menu, MenuItem, Popper, TextField,
} from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors } from './statics';
import { hexToRgbA } from '../commons/utils';

export const GlobalStyle = createGlobalStyle`
  body {
    /* overflow: overlay; */
    background-color: ${Colors.darkBg};
  }
  * {
    outline: none!important;
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif;
    box-sizing: border-box;
    /* font-weight: normal; */
    /* line-height: 1; */
  }
  a, a:hover {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  img {
    width: 100%;
  }
  svg {
    /* min-width: fit-content!important; */
    /* width: intrinsic!important; ; */
    display: table;
  }
  ::-webkit-scrollbar{
    width: 10px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb{
    background: ${Colors.grey};
    border-radius: 0px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: ${Colors.purple};
  }
  ::-webkit-scrollbar-track{
    background:  ${Colors.lightGrey};
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 0px  ${Colors.lightGrey};
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .MuiInput-underline {
    input, textarea {
      color: ${Colors.ligthWhite};
    }
    :before {
      border-bottom: 1px solid ${Colors.darkWhite};
    }
    .MuiSelect-select.MuiSelect-select {
      padding-right: 24px;
      color: ${Colors.ligthWhite};
    }
  }
  .MuiFormLabel-root {
    color: ${Colors.darkWhite};
    white-space: nowrap;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline { // input border
    border-color: ${Colors.purple};
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
  .MuiInput-underline:after, .MuiInput-underline.Mui-focused:after {
    border-color: ${Colors.purple};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: ${Colors.darkWhite};
  }
  .MuiPopover-paper {
    background: ${Colors.darkLightBg};
    padding: 0 5px;
  }
  .MuiAutocomplete-popper {
    * {
      color: ${Colors.white};
    }
  }
  .MuiTooltip-popper {
    font-size: 16px;
    color: ${Colors.white};
    * {
      color: ${Colors.white};
      font-size: 16px;
    }
  }
  .MuiMenuItem-root {
    color: ${Colors.grey};
  }
  #tableButton {
    display: flex;
    height: 45px;
    width: 43px; // temp
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      /* z-index: 1; */
      /* width: 24px; */
      width: inherit;
      /* height: 30px; */
      /* height: inherit; */
      height: 55px;
      right: 1px;
      background-color: ${Colors.darkSemiBg};
      border-left: 1px solid ${Colors.grey};
      /* border-bottom: 1px solid ${Colors.lightGrey}; */
      border-radius: 0;
      transform: translate(0, -5px);
    }
    svg {
      fill: ${Colors.ligthWhite}
    }
  }
  .MuiFormLabel-root.Mui-disabled {
    color: ${Colors.darkWhite};
    opacity: 0.5;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: ${Colors.darkWhite};
  }
  .MuiInputBase-input.Mui-disabled {
    opacity: 0.5;
  }
  .MuiSwitch-colorPrimary {
    color: ${Colors.purple};
    &.Mui-checked {
      color: ${Colors.purple};
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: ${Colors.purple};
    }
  }
  .MuiSwitch-colorSecondary {
    color: ${Colors.orange};
    &.Mui-checked {
      color: ${Colors.orange};
      :hover {
        background-color: ${hexToRgbA(Colors.purple, 0.2)}
      }
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: ${Colors.orange};
    }
  }
  .MuiPaper-root {
    background-color: ${Colors.darkSemiBg};
  }
  .MuiCheckbox-root {
    color: ${Colors.darkWhite};
  }
  .MuiIconButton-colorSecondary {
    color: ${Colors.darkWhite};
    &.Mui-checked {
      color: ${Colors.purple};
    }
    :hover {
      background-color: ${hexToRgbA(Colors.purple, 0.1)}
    }
  }
  .MuiListItemText-root {
    color: ${Colors.darkWhite};
  }
  .MuiSelect-icon {
    color: ${Colors.purple};
    &.Mui-disabled {
      color: ${Colors.darkLightBg};
    }
  }
  .fileInputWrapper {
    position: relative;
    input {
      padding-right: 115px;
    }
    .MuiButtonBase-root {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }
  .MuiSvgIcon-colorPrimary {
    color: ${Colors.darkWhite}!important;
  }
  .DraftEditor-editorContainer {
    min-height: 500px;
  }
`;

export const Simplelink = styled(Link)`
  display: inline-block;
  color: ${Colors.dark};
  transition: all 0.3s ease;
  user-select: none;
  font-weight: 600;
  :hover {
    color: ${Colors.dark};
  }
`;

export const CustomButton = styled(Button)`
  background-color: ${Colors.purple};
  color: ${Colors.white};
  text-transform: none;
  padding: 10px 30px;
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 6px;
  &.Mui-disabled {
    color: ${Colors.darkWhite}!important;
  }
  :hover {
    background-color: ${props => (props.light ? Colors.blueLight : props.white ? Colors.White : hexToRgbA(Colors.purple, 0.7))};
    color: ${props => (props.light ? Colors.blue : Colors.white)};
  }
  ${(props => props.simple && `
    background-color: transparent;
    color: ${Colors.lightBlack};
    :hover {
      background-color: transparent;
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.lined && `
    background-color: transparent;
    color: ${Colors.darkWhite};
    border: 1px solid ${Colors.darkWhite};
    :hover {
      background-color: transparent;
      color: ${Colors.darkWhite};
    }
  `)}
  ${(props => props.lightBlack && `
    background-color: ${hexToRgbA(Colors.lightBlack, 0.9)};
    color: ${Colors.white};
    // font-size: 18px;
    // line-height: 20px;
    :hover {
      background-color: ${Colors.lightBlack};
      color: ${Colors.white};
    }
  `)}
  ${(props => props.dark && `
    background-color: ${hexToRgbA(Colors.dark, 0.9)};
    color: ${Colors.white};
    // font-size: 18px;
    // line-height: 20px;
    :hover {
      background-color: ${Colors.dark};
      color: ${Colors.white};
    }
  `)}
  ${(props => props.lightBlue && `
    background-color: ${hexToRgbA(Colors.blueLight, 0.5)};
    color: ${Colors.blue};
    :hover {
      background-color: ${Colors.blueLight};
      color: ${Colors.blue};
    }
  `)}
   ${(props => props.darkGrey && `
    background-color: ${hexToRgbA(Colors.grey, 1)};
    color: ${Colors.white};
    :hover {
      background-color: ${Colors.lightBlack};
      color: ${Colors.white};
    }
  `)}
  ${(props => props.purple && `
    background-color: ${Colors.purple};
    color: ${Colors.white}!important;
    :hover {
      background-color: ${hexToRgbA(Colors.purple, 0.7)};
      color: ${Colors.white};
    }
  `)}
  ${(props => props.orange && `
    background-color: ${Colors.lightOrange};
    color: ${Colors.lightBlack};
    :hover {
      background-color: ${Colors.lightOrange};
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.red && `
    background-color: ${Colors.burgundy};
    color: ${Colors.white};
    :hover {
      background-color: ${Colors.burgundy};
      color: ${Colors.white};
    }
  `)}
  ${(props => props.grey && `
    background-color: ${Colors.lightGrey};
    color: ${Colors.lightBlack};
    &.MuiButton-root.Mui-disabled {
      color: ${hexToRgbA(Colors.lightBlack, 0.8)};
    }
    :hover {
      background-color: ${Colors.lightGrey};
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.semiLightGrey && `
    background-color: ${Colors.semiLightGrey};
    color: ${Colors.lightBlack};
    :hover {
      background-color: ${Colors.lightGrey};
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.pink && `
    background-color: ${hexToRgbA(Colors.pink, 0.5)};
    color: ${Colors.burgundy};
    :hover {
      background-color: ${hexToRgbA(Colors.pink, 0.5)};
      color: ${Colors.burgundy};
    }
  `)}
  ${(props => props.white && `
    background-color: ${Colors.white};
    color: ${Colors.lightBlack};
    border: 1px solid ${Colors.lightBlack};
    :hover {
      background-color: ${Colors.white};
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.yellow && `
    background-color: ${Colors.yellow};
    color: ${Colors.lightBlack};
    :hover {
      background-color: ${Colors.yellow};
      color: ${Colors.lightBlack};
    }
  `)}
  ${(props => props.large && `
    height: unset;
    padding: 20px 40px;
    border-radius: 14px;
    font-size: 18px;
  `)}
`;

export const CustomIconButton = styled(IconButton)`
  color: ${Colors.darkWhite};
  svg {
    /* fill: ${Colors.dark}; */
  }
  * {
    border-radius: 5px;
  }
  &.MuiIconButton-root {
    border-radius: 5px;
  }
  ${props => props.noPad && `
    &.MuiIconButton-root {
      padding: 0;
    }
  `}
`;

export const CustomFormControl = styled(FormControl)`
  > label {
    font-size: 20px;
    color: ${props => props.theme.text1};
    font-weight: 600;
  }
  .MuiAutocomplete-tag {
    color: ${Colors.blueBlack2};
    border-radius: 10px;
    background: ${Colors.error};
    font-weight: 600;
    .MuiChip-deleteIcon{
      fill: ${hexToRgbA(Colors.blueBlack2, 0.3)};
      :hover {
        fill: ${hexToRgbA(Colors.blueBlack2, 0.6)};
      }
    }
  }
  .MuiInputBase-root {
    color: ${props => props.theme.text2};
    border-radius: 5px;
    background: ${props => props.theme.inputBackg};
    padding: 0;
    max-height: 190px;
    /* overflow: auto; */
    overflow: hidden;
    min-height: 38px; // temp
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, -25px) scale(0.75);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.inputBorder};
    border-width: 1px;
    border-style: solid;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.inputBorder};
  }
  ${props => (props.error && `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${Colors.red}!important;
    }
  `)}
`;

export const CustomFileText = styled(TextField)`
  ${props => props.disabled && `
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
      border-width: 1px;
      border-style: solid;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
  `}
`;

export const CustomMenuItem = styled(MenuItem)`
  ${props => props.active && `
    color: #2144D4;
    svg {
      path {
        fill: #2144D4;
      }
      circle {
        fill: #2144D4;
      }
      rect {
        fill: #2144D4;
      }
    }
  `}
`;

export const TabbleStatusBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: ${props => (props.status === 0
    ? hexToRgbA(Colors.warning, 0.1) : props.status === 1
      ? hexToRgbA(Colors.lightBlue, 0.1) : props.status === 2
        ? hexToRgbA(Colors.error, 0.1) : props.status === 3
          ? hexToRgbA(Colors.success, 0.1) : 'red')};
  color: ${props => (props.status === 0
    ? Colors.warning : props.status === 1
      ? Colors.lightBlue : props.status === 2
        ? Colors.error : props.status === 3
          ? Colors.success : 'red')};
  border: 1px solid;
  border-color: ${props => (props.status === 0
    ? hexToRgbA(Colors.warning, 0.7) : props.status === 1
      ? hexToRgbA(Colors.lightBlue, 0.7) : props.status === 2
        ? hexToRgbA(Colors.error, 0.7) : props.status === 3
          ? hexToRgbA(Colors.success, 0.7) : 'red')};
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const SmallWidget = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  background-color: ${Colors.darkLightBg};
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  /* height: 128px; */
  /* padding: 10px 40px; */
  height: 100%;
  padding: 10px 16px;
  .content {
    z-index: 1;
    overflow: hidden;
    padding-left: 30px;
    padding-left: 10%;
    h6 {
      font-size: 16px;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${Colors.darkWhite};
      max-width: 100%;
      padding-bottom: 5px;
    }
    h5 {
      font-size: 28px;
      font-weight: 600;
      color: ${Colors.lightWhite};
      padding-top: 5px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    z-index: 1;
    padding-right: 10px;
    svg {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }
  @media (max-width: 768px) {
    padding: 24px;
    .bottom {
      display: none;
    }
    .content, .content h6, .content h5 {
      padding: 0;
    }
  }
`;

export const MiddleWidget = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  background-color: ${Colors.darkLightBg};
  padding: 20px;
  overflow: hidden;
  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: ${Colors.dark};
    }
  }
`;

export const MainWrapper = styled.div`
  /* padding: ${props => (props.activeUser ? '0 40px' : '0')}; */
  width: ${props => (props.sideOpen ? '100%' : 'calc(100% - 250px)')};
  width: ${props => (!props.singed && '100%')};
  margin-left: auto;
  transition: all 0.5s ease;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 74px);
  background-color: ${Colors.darkBg};
  overflow: hidden;
  padding: 40px;
  .pageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -5px;
    > * {
      margin: 5px;
      min-width: 100px;
    }
  }
  .filterButtons {
    margin: -5px;
    > * {
      margin: 5px;
    }
  }
  h1.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* color: ${Colors.dark}; */
    color: ${Colors.darkWhite};
    margin-bottom: 15px;
  }
`;

export const SidebarItem = styled(Link)`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  user-select: none;
  transition: all 0.5s;
  width: 100%;
  padding-left: 15px;
  span {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    padding-left: 15px;
    color: ${Colors.darkWhite};
    transition: all 0.5s;
  }
  &:last-of-type {
    transition: all 0.5s;
    margin-bottom: 0;
  }
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    path {
      fill: ${Colors.darkWhite};
    }
  }
  ${props => props.active && `
    span {
      color: ${Colors.ligthWhite};
    }
    svg path {
      fill: ${Colors.ligthWhite};
    }
  `}
  :hover {
    background-color: ${hexToRgbA(Colors.grey, 0.1)};
    border-radius: 15px;
    color: ${Colors.ligthWhite};
    span {
      color: ${Colors.ligthWhite};
    }
    svg path {
      fill: ${Colors.ligthWhite};
    }
  }
`;

export const SidebarWrapper = styled.div`
  display: flex;
  min-width: ${props => (props.open ? 0 : 250)}px;
  max-width: ${props => (props.open ? 0 : 250)}px;
  height: 100vh;
  z-index: 10; // temp
  background-color: ${Colors.dashboardGrey};
  transition: all 0.5s ease;
  @media (max-width : 768px) {
    min-width: 0px;
    max-width: 0px;
  }
  .leftFixed {
    display: flex;
    position: fixed;
    flex-direction: column;
    width: ${props => (props.open ? 0 : 250)}px;
    /* padding: 10px 20px 50px 20px; */
    height: 100vh;
    transition: all 0.5s;
    background-color: ${Colors.darkSemiBg};
    box-shadow: 0px 0px 10px rgb(0 0 0 / 70%);
    min-width: ${props => (props.open ? 0 : 250)}px;
    max-width: ${props => (props.open ? 0 : 250)}px;
    padding: ${props => (props.open ? 0 : '10px 10px 50px 10px')};
    overflow: hidden;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 150px;
      > * {
        width: 80%;
      }
      svg {
        width: 100%;
        width: ${props => (props.open ? 0 : '100%')};
        min-width: ${props => (props.open && 0)};
      }
    }
    .navigator {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex: 1;
      position: relative;
      .chosen {
        position: absolute;
        background-color: ${Colors.purple};
        box-shadow: 0px 10px 30px rgb(244 67 54 / 15%);
        transition: all 0.5s;
        border-radius: 10px;
        left: -10px;
        width: 5px;
        height: 40px;
        z-index: -1;
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  height: 74px;
  .fixed {
    transition: all 0.5s ease;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 18px 4px 35px rgba(108, 109, 98, 0.1);
    background-color: ${Colors.darkSemiBg};
    width: ${props => (props.sideOpen ? '100%' : 'calc(100% - 250px)')};
    position: fixed;
    z-index: 9;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .left {
    display: flex;
    align-items: center;
    color: ${Colors.darkWhite};
    .MuiButtonBase-root {
      padding: 0;
      margin: 12px;
      svg rect {
        fill: ${Colors.darkWhite};
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    > .MuiButtonBase-root {
      margin: 0 25px;
    }
    .counter {
      position: absolute;
      width: 26px;
      height: 26px;
      right: 0;
      top: 0;
      border-radius: 100%;
      color: ${Colors.dark};
      background: ${Colors.yellow};
      border: 2px solid ${Colors.darkLightBg};
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 25px;
      position: relative;
      :before {
        content: '';
        position: absolute;
        left: 0;
        top: 10%;
        height: 80%;
        width: 1px;
        background: ${Colors.grey};
        opacity: .3;
      }
      .capitalNameBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        min-width: 50px;
        border-radius: 100%;
        margin-right: 15px;
        background-color: ${Colors.darkBg};
        span {
          color: ${Colors.darkWhite};
          font-size: 18px;
          font-weight: 500;
        }
      }
      .name {
        h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: ${Colors.darkWhite};
        }
        p {
          font-size: 12px;
          line-height: 15px;
          color: ${Colors.grey};
        }
      }
    }
  }
  @media (max-width: 475px) {
    .right {
      > .MuiButtonBase-root {
        margin: 0 10px;
      }
      .profile {
        padding-left: 10px;
        .capitalNameBox {
          width: 40px;
          height: 40px;
          min-width: 40px;
          margin-right: 0px;
        }
        .name {
          display: none;
        }
      }
    }
  }
`;

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  /* background-color: ${Colors.purple}; */
  /* padding: 100px; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: right top;
  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bg});
    background-repeat: no-repeat;
    background-position: right top;
    transform: rotate(180deg);
    z-index: -1;
  }
  .right {
    width: 35%;
    min-width: 320px;
    max-height: 80vh;
    margin: auto;
    background: ${hexToRgbA(Colors.white, 0.7)};
    backdrop-filter: blur(10px);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 5px 30px -10px rgb(14 17 33);;
    .logo {
      display: flex;
      justify-content: center;
      padding: 0;
    }
    >* {
      padding-top: 50px;
    }
    .form {
      > * + * {
        padding-top: 15px;
      }
    }
    .bottom {
      width: 100%;
      div {
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        ${Simplelink} {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          color: ${Colors.blue};
          margin-left: auto;
          :hover {
            color: ${Colors.purple};
          }
        }
      }
    }
  }
`;

export const SubSideMenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  border-bottom: 1px solid ${Colors.greyBorder};
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  :first-of-type {
    border-top: 1px solid ${Colors.greyBorder};
  }
  :hover {
    background-color: ${hexToRgbA(Colors.greyBorder, 0.7)};
    ::after {
      content: '';
      position: absolute;
      right: 1px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: ${hexToRgbA(Colors.yellow, 0.5)};
    }
  }
  p {
    font-size: 16px;
    white-space: nowrap;
    padding-right: 5px;
    color: ${Colors.darkGrey};
  }
  svg {

  }
  ${props => props.active && `
    background-color: ${hexToRgbA(Colors.greyBorder, 0.9)};
    ::after {
      content: '';
      position: absolute;
      right: 1px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: ${hexToRgbA(Colors.yellow, 0.8)};
    }
  `};
`;

export const SubSideMenu = styled.div`
  width: 250px;
  min-width: 250px;
  border: 1px solid ${Colors.greyBorder};
  .fixed {
    /* position: fixed;
    top: 74px; */
    h2 {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
      padding: 30px;
    }
  }

`;

export const CustomFade = styled(Fade)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 35%;
  max-width: 400px;
  max-height: 90vh;
  padding: 35px 40px;
  border-radius: 15px;
  background-color: ${Colors.darkSemiBg};
  overflow: overlay;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.lightGrey};
    padding-bottom: 10px;
    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: ${Colors.darkWhite};
    }
    svg {
      fill: ${Colors.darkWhite};
    }
  }
  .content {
    .form {
      > * {
        margin-top: 15px;
      }
    }
    .itemWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      p {
        color: ${Colors.darkWhite};
      }
    }
    .similarIdeas {
      h2, h3 {
        text-align: center;
        padding-top: 10px;
        font-size: 16px;
        line-height: 17px;
      }
      h2 {
        font-weight: bold;
      }
    }
  }
  .dateWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -15px -10px!important;
    padding-top: 15px;
    > * {
      margin: 15px 10px;
      flex: 1;
      min-width: 150px;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    ${CustomButton} {
      margin-left: 15px;
      :first-of-type {
        margin-left: 0;
      }
    }
  }
`;

export const CustomFadeInfo = styled(Fade)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* min-width: 40%; */
  width: 400px;
  max-width: 90%;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: ${Colors.darkSemiBg};
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    h2 {
      padding-top: 40px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.lightWhite};
    }
    p {
      font-size: 14px;
      line-height: 20px;
      padding-top: 15px;
      text-align: center;
      color: ${Colors.darkWhite}
    }
    ${CustomButton} {
      margin-top: 50px;
      width: 100%;
    }
    .buttonWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      /* >* {
        margin-left: 20px;
      }
      :first-child {
        margin-left: 0;
      } */
      ${CustomButton} {
        margin-top: 50px;
        & + ${CustomButton} {
          margin-left: 20px;
        }
      }
    }
  }
`;

export const ChartWrapper = styled.div`
  background-color: ${Colors.darkSemiBg};
  border-radius: 15px;
  height: ${props => props.height || '300px'};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  svg g g {
    text:only-child {
      fill: ${Colors.dark}!important;
    }
    text {
      fill: ${Colors.darkWhite}!important;
    }
    rect + text {
      fill: ${Colors.dark}!important;
    }
    rect + rect + text {
      fill: ${Colors.darkWhite}!important;
    }
  }
`;

export const MobileMoreMenu = styled(Menu)`
`;

export const MobileMoreMenuItem = styled(MenuItem)`
  display: flex;
  font-size: 13px;
  background: ${Colors.grayExtraLight};
  color: ${Colors.dark};
  height: 35px;
  /* & + & {
    border-top: 1px solid ${Colors.redLight};
  } */
  :active {
    background: ${Colors.redLight};
  }
  &.MuiButtonBase-root {
    color: ${Colors.darkWhite};
  }
  svg {
    margin-right: 5px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    path {
      fill: ${Colors.ligthWhite};
    }
  }
`;

export const TransferWrapper = styled.div`
  .MuiButton-root {
    padding: 3px 9px;
    font-size: 0.8125rem;
    color: ${Colors.darkWhite};
    border-color: ${Colors.darkWhite};
  }
  button + button {
    margin-top: 4vh;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid ${Colors.darkWhite};
  }
`;

export const CustomDialog = styled.div`
  /* width: clamp(320px, 40%, 90%); */
  padding: 30px;
  h2 {
    color: ${Colors.ligthWhite};
    padding-bottom: 20px;
  }
  .content {
    >* + * {
      margin-top: 20px;
    }
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -5px -10px;
    padding-top: 20px;
    >* {
      margin: 5px 10px;
    }
  }
`;

export const CustomPopperUser = styled(Popper)`
  z-index: 10;
  ${CustomButton} {
    justify-content: flex-start;
    margin-top: 5px;
    :first-of-type {
      margin-top: 0;
    }
  }
  .MuiPaper-root {
    border: 1px solid ${hexToRgbA(Colors.grey, 0.5)};
    border-radius: 10px;
    position: relative;
  }
  .MuiPaper-elevation1 {
    /* box-shadow: 0px 20px 30px rgba(164, 130, 132, 0.3); */
    top: 10px;
    /* right: 20px; // temp */
    padding: 10px 0;
  }
  .MuiButton-label {
    color: ${Colors.darkWhite};
  }
  .MuiButton-startIcon {
    svg, svg path{
      fill: ${Colors.darkWhite};
    }
  }
  .triangle {
    position: absolute;
    top: -10px;
    right: -2px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${hexToRgbA(Colors.grey, 0.5)} transparent;
    ::after, ::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 14px;
      left: -5px;
      top: -2px;
      transform: rotate(45deg);
      background-color: ${hexToRgbA(Colors.grey, 0.5)};
    }
    ::before {
      transform: rotate(-45deg);
      left: unset;
      right: -5px;
    }
  }
  @media (max-width: 768px) {
    .triangle {
      display: none;
    }
  }
`;

export const WebPageWrapper = styled.div`
  
`;


export const PaymentPage = styled.div`
  padding: 16px;
  h3 {
    color: ${Colors.white};
    font-size: 20px;
  }
  .inputArea {
    display: flex;
    padding: 25px 0;
    gap: 15px;
    align-items: flex-end;
    > .MuiFormControl-root {

    }
    p {
      color: ${Colors.white};
      min-width: 300px;
    }
  }
`;
