/* eslint-disable react/prop-types */
import {
  Backdrop, MenuItem, Modal, TextField,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { CustomButton, CustomFade, CustomIconButton } from '../../assets/styled';
import { bugTypes } from '../../commons/constants';
import * as thunks from '../../store/thunks';

function ChangeBugStatus({ data, onClose }) {
  const [selectedType, setSelectedType] = useState(null);
  const [warningOpen, setWarningOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(null);

  const dispatch = useDispatch();
  const changeWordReport = useCallback((id, changeData) => dispatch(thunks.changeWordReport(id, changeData)), [dispatch]);

  const saveFunc = (checked) => {
    if (isFixed) {
      if (isFixed && !checked) {
        setWarningOpen(true);
        return;
      }
    }
    changeWordReport(data.id, { type: selectedType, isFixed });
    setWarningOpen(false);
    onClose();
  };

  useEffect(() => {
    if (data) {
      setSelectedType(data.type);
      setIsFixed(data.isFixed);
    }
  }, [data]);

  return (
    <>
      <Modal
        open
        onClose={() => onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in>
          <div>
            <div className="header">
              <h1>Hata Durumu Değiştir</h1>
              <CustomIconButton onClick={() => onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Bug Type"
                  value={selectedType}
                  onChange={e => setSelectedType(e.target.value)}
                >
                  {bugTypes?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Çözüm Durumu"
                  value={isFixed}
                  onChange={e => setIsFixed(e.target.value)}
                >
                  <MenuItem key="0" value={false}>Çözülmedi</MenuItem>
                  <MenuItem key="1" value>Tamamlandı</MenuItem>
                </TextField>
                <div className="buttonWrapper">
                  <CustomButton onClick={() => onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        warningOpen && (
          <Modal
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <CustomFade in>
              <div>
                <div className="header">
                  <h1>DİKKAT!</h1>
                  <CustomIconButton onClick={() => setWarningOpen(false)}><Close /></CustomIconButton>
                </div>
                <div className="content">
                  <div className="form" style={{ color: 'white' }}>
                    <p>Hata durumunu <strong><u>BAŞARILI</u></strong> olarak değiştiriyorsunuz.</p>
                    <p>Eğer onaylarsanız kullanıcıya bilgi maili gidecek.</p>
                    <p>Onaylıyor musunuz?</p>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => setWarningOpen(false)} lined>
                        Vazgeç
                      </CustomButton>
                      <CustomButton purple onClick={() => saveFunc(true)}>
                        Çözümü Onayla
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </CustomFade>
          </Modal>
        )
      }
    </>
  );
}

export default ChangeBugStatus;
