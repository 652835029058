/* eslint-disable react/prop-types */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

// 1 = ios, 2 = android, 3 = web
const months = ['Ock', 'Şbt', 'Mrt', 'Nsn', 'Mys', 'Haz', 'Tmz', 'Agu', 'Eyl', 'Ekm', 'Ksm', 'Arl'];

function Bar({ statics }) {
  function prepareData() {
    const data = [];
    for (let i = 0; i < months.length; i++) {
      data.push({
        Aylar: months[i],
        Havale: 0,
        Kredi_Karti: 0,
        Website: 0,
      });
    }
    for (let i = 0; i < statics.length; i++) {
      if (moment(statics[i].start_date).get('year') === moment().get('year')) {
        const date = moment(statics[i].start_date).get('month');
        switch (statics[i].Payment?.payment_type) {
          case 'DEBIT':
            data[date].Havale += statics[i].price;
            break;
          case 'CREDIT':
            data[date].Kredi_Karti += statics[i].price;
            break;
          default:
            break;
        }
      }
    }
    return data;
  }

  return (
    <ResponsiveBar
      data={prepareData()}
      keys={[
        'Kredi_Karti',
        'Havale',
      ]}
      indexBy="Aylar"
      margin={{
        top: 50, right: 130, bottom: 50, left: 60,
      }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      valueFormat={{ format: '', enabled: false }}
      // colors={{ scheme: 'nivo' }}
      colors={{ scheme: 'set2' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'fries',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'sandwich',
          },
          id: 'lines',
        },
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Aylar',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `Satış Miktarı ${moment().get('year')}`,
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

export default Bar;
