/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid, InputAdornment, TextField,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cancel, Check, CheckCircle, Close, MoreVert, Search,
} from '@material-ui/icons';
import moment from 'moment';
import {
  CustomButton,
  CustomIconButton,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper, TabbleStatusBox,
} from '../assets/styled';
import Table from '../components/Table';
import * as thunks from '../store/thunks';
import { Edit, Plus } from '../assets/icons';
import NewUser from '../components/modals/NewUser';
import AssignPackage from '../components/modals/AssignPackage';

function Users() {
  const [state, setState] = React.useState({
    // searchFilter: '',
  });
  const [searchFilter, setSearchFilter] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [activeFilter, setActiveFilter] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAssignPackageModal, setOpenAssignPackageModal] = useState(false);
  const [edit, setEdit] = useState(false);

  // Redux Store Verisi çağırma
  const allUsers = useSelector(state1 => state1.allUsers);

  // Action Listesi
  const dispatch = useDispatch();

  const deleteUser = useCallback(() => dispatch(thunks.deleteUser(selectedUserId)), [dispatch, selectedUserId]);

  // Fonksiyon Direkt Çalışacaksa
  useEffect(() => {
    dispatch(thunks.getUser());
    dispatch(thunks.getPackage());
  }, [dispatch]);

  const getWhatAppLink = (phone) => {
    if (phone?.charAt(0) === '0') {
      phone = `+9${phone}`;
    } else if (phone?.charAt(0) === '5') {
      phone = `+90${phone}`;
    }
    phone = phone?.replace(/\s/g, '');
    return `https://wa.me/${phone}?text=Merhaba`;
  };

  const columns = [
    {
      name: 'is_removed',
      text: 'Durumu',
      filterable: true,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 2 : 3}>
          {value ? 'Pasif' : 'Aktif'}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'id',
      text: 'Id',
      filterable: true,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
    },
    {
      name: 'name',
      text: 'İsim',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'mail',
      text: 'Mail',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
    },
    {
      name: 'Devices',
      text: 'Cihaz',
      filterable: false,
      editable: false,
      align: 'center',
      sortable: true,
      type: 'text',
      customBody: value => (
        value ? (
          <div>
            {value.devices.length} / {value.deviceCount}
          </div>
        ) : '-'
      ),
    },
    {
      name: 'Package',
      text: 'Geçerli Paketleri',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <div style={{ display: 'flex', gap: 3 }}>
          {
            value.map(item => (
              <Tooltip placement="top" title={`Bitiş Tarihi: ${moment(item.UserPackages.end_date).format('DD.MM.YYYY')}`}>
                <TabbleStatusBox status={moment(item.UserPackages.end_date) < moment() ? 2 : 3}>
                  {item.name}
                </TabbleStatusBox>
              </Tooltip>
            ))
          }
        </div>
      ),
    },
    {
      name: 'is_activated',
      text: 'Aktivasyon',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: true,
      type: 'text',
      customBody: value => (
        <TabbleStatusBox status={value ? 3 : 2}>
          {!value ? <Close /> : <Check />}
        </TabbleStatusBox>
      ),
    },
    {
      name: 'Action',
      text: 'Action',
      filterable: false,
      editable: false,
      align: 'left',
      sortable: false,
      type: 'action',
      disableSetting: false,
      customBody: (value, columnName, index, row) => (
        <div id="tableButton">
          <CustomIconButton onClick={(e) => { setState({ [`anchorMobileTable${row.id}`]: e.currentTarget }); setSelectedUserId(row.id); }}>
            {/* <MobileTripleIcon /> */}
            <MoreVert />
          </CustomIconButton>
          {
            state[`anchorMobileTable${row.id}`] && (
              <MobileMoreMenu
                id="mobileMoreMenu"
                anchorEl={state[`anchorMobileTable${row.id}`]}
                keepMounted
                open={Boolean(state[`anchorMobileTable${row.id}`])}
                onClose={() => { setSelectedUserId(null); setEdit(false); setState({ [`anchorMobileTable${row.id}`]: null }); }}
                // placement="left"
                anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: state[`anchorMobileTable${row.id}`],
                  },
                }}
              >
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setEdit(true); setOpenUserModal(true); }}
                >
                  <Edit />
                  Kullanıcıyı Düzenle
                </MobileMoreMenuItem>
                {/* <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { setState({ [`anchorMobileTable${row.id}`]: null }); setOpenAssignPackageModal(true); }}
                >
                  <Plus />
                  Paket Ata
                </MobileMoreMenuItem> */}
                <MobileMoreMenuItem
                  // active={!state[`columnVisibility${tabValue}`]}
                  onClick={() => { deleteUser(); setState({ infoDeleteModal: true, [`anchorMobileTable${row.id}`]: null }); }}
                >
                  {row.is_removed ? <CheckCircle /> : <Cancel />}
                  {row.is_removed ? 'Aktif Yap' : 'Pasif Yap'}
                </MobileMoreMenuItem>
              </MobileMoreMenu>
            )
          }
        </div>
      ),
    },
  ];

  const filteredActive = filterType !== 'all' ? ((filterType === 'true' || filterType === 'false') ? allUsers?.filter(item => item.is_removed?.toString() === filterType) : allUsers?.filter(item => item.user_type_id?.toString() === filterType)) : allUsers;
  const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
  const filteredData = searchFilter.length > 0 ? (filteredActive && filteredActive?.filter(item => Object?.keys(item)?.some(key => typeof (item[(key)]) === 'string'
  && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : filteredActive;

  return (
    <PageWrapper>
      <Grid container spacing="3">
        <Grid item lg={12} xs={12}>
          <div className="pageHeader">
            <TextField
              // label="Ara"
              placeholder="Tablo'da ara"
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search color="primary" /></InputAdornment>,
                endAdornment: searchFilter?.length > 0 && <InputAdornment position="end"><CustomIconButton noPad onClick={() => setSearchFilter('')}><Close color="primary" /></CustomIconButton></InputAdornment>,
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
            <CustomButton purple onClick={() => { setEdit(false); setOpenUserModal(true); setSelectedUserId(null); }} startIcon={<Plus />}>Kullanıcı Ekle</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <div className="filterButtons">
            <CustomButton orange={activeFilter === 1} purple onClick={() => { setActiveFilter(1); setFilterType('all'); }}>Tümü</CustomButton>
            {/* <CustomButton orange={activeFilter === 2} purple onClick={() => { setActiveFilter(2); setFilterType('1'); }}>Admin</CustomButton>
            <CustomButton orange={activeFilter === 3} purple onClick={() => { setActiveFilter(3); setFilterType('2'); }}>Kullanıcı</CustomButton> */}
            <CustomButton orange={activeFilter === 4} purple onClick={() => { setActiveFilter(4); setFilterType('false'); }}>Aktif Olan</CustomButton>
            <CustomButton orange={activeFilter === 5} purple onClick={() => { setActiveFilter(5); setFilterType('true'); }}>Pasif Olan</CustomButton>
          </div>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Table
            data={filteredData?.sort?.((a, b) => b.id - a.id) || []}
            columns={columns || []}
            id="reportsTable"
            options={{
              selectableRows: 'none',
              header: {
                visible: true,
                columnSetting: false,
              },
              filter: {
                show: true,
                filters: [],
                // onChange: (state, value, text, type) => console.log(state, value, text, type),
                // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
              },
              pagination: {
                active: true,
                perPage: 20,
              },
            }}
            // rowClick={row => console.log(row)}
            // rowClick={() => window.location.replace('/SuggestionDetail')}
          />
        </Grid>
      </Grid>
      {
        openUserModal && (
          <NewUser
            open={openUserModal}
            onClose={() => setOpenUserModal(false)}
            edit={edit}
            selectedUserId={selectedUserId}
            userData={allUsers?.filter(select => select?.id === selectedUserId)[0]}
          />

        )
      }
      {
        openAssignPackageModal && (
          <AssignPackage
            open={openAssignPackageModal}
            onClose={() => setOpenAssignPackageModal(false)}
            selectedUserId={selectedUserId}
            userData={allUsers?.find(select => select?.id === selectedUserId)}
          />

        )
      }
    </PageWrapper>
  );
}

export default Users;
