// import FormData from 'form-data';

export const request = async (url, method, data = false, authorization = false) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (authorization) {
    const token = localStorage.getItem('token');
    if (token) {
      headers.authorization = token;
    }
  }
  const options = {
    headers,
    method,
  };

  if (data) {
    options.body = data;
  }
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401 && !window.location.hash.includes('#/ForgetPassword/')) {
          localStorage.removeItem('token');
          window.location.href = '#/Login';
          throw Error(response.statusText);
        }
      }
      return response;
    })
    .then(response => response.json())
    .catch(() => 'invalid');
};

export const fileRequest = (url, method, file, data) => {
  const headers = {
    Authorization: localStorage.getItem('token'),
  };
  const options = {
    headers,
    method,
  };
  const fd = new FormData();
  // for (let i = 0; i < files.length; i++) {
  //   var key = Object.keys(files[i]);
  //   fd.append(key[0], files[i][key[0]]);
  // }
  fd.append('file', file);
  // fd.append('text', text);
  fd.append('data', data);
  options.body = fd;
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => response.json())
    .catch(() => 'invalid');
};

// export const filesRequest = (url, method, data = false, file) => {
//   const myHeaders = new Headers();
//   myHeaders.append('Authorization', localStorage.getItem('token'));

//   const formdata = new FormData();
//   formdata.append('file', file);
//   formdata.append('isCreated', data);

//   const requestOptions = {
//     method,
//     headers: myHeaders,
//     body: formdata,
//     redirect: 'follow',
//   };

//   return fetch(url, requestOptions)
//     .then((response) => {
//       if (!response.ok) {
//         throw Error(response.statusText);
//       }
//       return response;
//     })
//     .then(response => response.json())
//     .catch(() => 'invalid');
// };

const saveAs = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const anchorElem = document.createElement('a');
  anchorElem.style = 'display: none';
  anchorElem.href = url;
  anchorElem.download = fileName;
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem);
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1000);
};

export const fileDownload = (url, method, data = false) => {
  const headers = {
    authorization: localStorage.getItem('token'),
    responseType: 'arraybuffer',
  };
  const options = {
    headers,
    method,
  };
  if (data) {
    options.body = data;
  }
  fetch(url, options)
    .then(res => res.blob())
    .then((blob) => {
      const fileName = 'export.xlsx';
      saveAs(blob, fileName);
    });
};

export const hexToRgbA = (hex, opacity) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }
  // throw new Error('Bad Hex');
  return 'lime';
};

export const sinceTimer = (date) => {
  let postTime; let postDateMounth; let postDateWeek;
  let postDateDay; let postDateHour; let postDateMin;
  let postTimeStr; let postDateYear;
  if (date) {
    postTime = Math.abs(new Date().getTime() - new Date(date).getTime());
    if (postTime) {
      postDateYear = Math.floor(postTime / (1000 * 60 * 60 * 24 * 365));
      postDateMounth = Math.floor(postTime / (1000 * 60 * 60 * 24 * 30));
      postDateWeek = Math.floor(postTime / (1000 * 60 * 60 * 24 * 7));
      postDateDay = Math.floor(postTime / (1000 * 60 * 60 * 24));
      postDateHour = Math.floor(postTime / (1000 * 60 * 60));
      postDateMin = Math.floor(postTime / (1000 * 60));
      postTimeStr = 'şimdi';
      if (postDateMin > 0) {
        postTimeStr = `${postDateMin} dakika önce`;
      }
      if (postDateHour > 0) {
        postTimeStr = `${postDateHour} saat önce`;
      }
      if (postDateDay > 0) {
        postTimeStr = `${postDateDay} gün önce`;
      }
      if (postDateWeek > 0) {
        postTimeStr = `${postDateWeek} hafta önce`;
      }
      if (postDateMounth > 0) {
        postTimeStr = `${postDateMounth} ay önce`;
      }
      if (postDateYear > 0) {
        postTimeStr = `${postDateYear} yıl önce`;
      }
    }
  }
  return postTimeStr;
};

// data = date;
// type = dm (gün ay), dmy (gün ay yıl), dmyNum (gün ay[rakamla] yıl)
export const dateTool = (data, type) => {
  let options = [];
  if (type === 'dm') {
    options = {
      month: 'long', day: 'numeric',
    };
  } else if (type === 'dmy') {
    options = {
      year: 'numeric', month: 'long', day: 'numeric',
    };
  } else if (type === 'dmyNum') {
    options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
    };
  } else if (type === 'dashDate') {
    const fixedDate = new Date(data);
    options = {
      month: 'long',
    };
    const regularMonth = fixedDate.toLocaleDateString('tr-TR', options);
    options = {
      day: 'numeric',
    };
    const regularDay = fixedDate.toLocaleDateString('tr-TR', options);
    options = {
      year: 'numeric',
    };
    const regularYear = fixedDate.toLocaleDateString('tr-TR', options);
    const newType = `${regularMonth} ${regularDay},  ${regularYear}`;
    return newType;
  } else {
    options = {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
    };
  }
  const fixedDate = new Date(data);
  const regularDate = fixedDate.toLocaleDateString('tr-TR', options);
  return regularDate;
};

export const convertToLink = (text) => {
  const text2 = text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/ç/g, 'c')
    .replace(/ı/g, 'i')
    .replace(/ş/g, 's')
    .replace(/ü/g, 'u')
    .replace(/ö/g, 'o')
    .replace(/ğ/g, 'g')
    .replace('?', '')
    .replace('!', '')
    .replace(',', '')
    .replace(':', '')
    .replace("'", '')
    .replace('.', '');
  return `/${text2}`;
};
