import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Users from '../pages/Users';
import Admin from '../pages/Admin';
import Languages from '../pages/Languages';
import Words from '../pages/Words';
import Categories from '../pages/Categories';
import Packages from '../pages/Packages';
import WebPage from '../pages/WebPage';
import Coupons from '../pages/Coupons';
import CreateWords from '../pages/CreateWords';
import Blogs from '../pages/Blogs/Blogs';
import NewBlog from '../pages/Blogs/NewBlog';
import SingleBlog from '../pages/Blogs/Single';
import HelpersPage from '../pages/HelpersPage';
import Payments from '../pages/Payments';
import BulkSales from '../pages/BulkSales';
import BugReports from '../pages/BugReports';

const Routes = () => (
  <div>
    <Route path="/Login" exact component={Login} />
    <Route path="/" exact component={Dashboard} />
    <Route path="/Users" exact component={Users} />
    <Route path="/Admin" exact component={Admin} />
    <Route path="/Languages" exact component={Languages} />
    <Route path="/Words" exact component={Words} />
    <Route path="/CreateWords/:id" exact component={CreateWords} />
    <Route path="/Categories" exact component={Categories} />
    <Route path="/Packages" exact component={Packages} />
    <Route path="/Coupons" exact component={Coupons} />
    <Route path="/WebPage" exact component={WebPage} />
    <Route path="/Blogs" exact component={Blogs} />
    <Route path="/NewBlog" exact component={NewBlog} />
    <Route path="/Blog/:id" exact component={SingleBlog} />
    <Route path="/Helpers" exact component={HelpersPage} />
    <Route path="/Payments" exact component={Payments} />
    <Route path="/BulkSales" exact component={BulkSales} />
    <Route path="/Bug-reports" exact component={BugReports} />
  </div>
);

export default withRouter(Routes);
