import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ChartWrapper, PageWrapper } from '../assets/styled';
import Widgets from '../components/Widgets';
import Bar from '../components/chart/Bar';
// import Line from '../components/chart/Line';
import Pie from '../components/chart/Pie';
import Table from '../components/Table';

const columns = [
  {
    name: 'id',
    text: 'ID',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'User',
    text: 'Satın alan',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value.name} - {value.mail}</span>
    ),
  },
  {
    name: 'end_date',
    text: 'Paket Bitişi',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{moment(value).format('DD.MM.YYYY')}</span>
    ),
  },
  {
    name: 'Package',
    text: 'Alınna Paket',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value.name}</span>
    ),
  },
  {
    name: 'Payment',
    text: 'Ödeme Tipi',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value?.is_bulk ? 'Toplu Satış Kaydı' : value?.payment_type}</span>
    ),
  },
  {
    name: 'Payment',
    text: 'Kupon',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value?.coupon_code || '-'}</span>
    ),
  },
  {
    name: 'price',
    text: 'Fiyat',
    filterable: true,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <span>{value && new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value || 0)}</span>
    ),
  },
];

function DashboardHook() {
  const activeUser = useSelector(state1 => state1.activeUser);
  const statics = useSelector(state1 => state1.statics);

  const totalSalesCount = statics.filter(s => !s.Payment.is_bulk)?.length || [];
  const totalSales = statics.filter(s => !s.Payment.is_bulk).reduce((a, b) => a + b?.price, 0) || 0;

  const lastMothDatas = statics.filter(s => !s.Payment.is_bulk && moment(s.start_date).month() === moment().month() - 1);
  const lastMonthSalesCount = lastMothDatas.length || 0;
  const lastMonthSales = lastMothDatas.reduce((a, b) => a + b?.price, 0) || 0;

  const currentMothDatas = statics.filter(s => !s.Payment.is_bulk && moment(s.start_date).month() === moment().month());
  const currentMonthSalesCount = currentMothDatas.length || 0;
  const currentMonthSales = currentMothDatas.reduce((a, b) => a + b?.price, 0) || 0;

  const totalBulk = statics.filter(s => s.Payment.is_bulk).reduce((a, b) => a + b?.price, 0) || 0;

  return (
    activeUser?.user_type_id === 1 && (
      <PageWrapper>
        <Grid container spacing={3}>
          {
            statics && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={3} style={{ height: 'calc(100% + 24px)' }}>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplam Satılan Paket" numberData={`Toplam ${totalSalesCount} Adet`} icon={null} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplam Satılan Miktar" numberData={new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(totalSales)} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Geçen Ay Satılan" numberData={`${lastMonthSalesCount} Adet - ${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(lastMonthSales)}`} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Bu Ay Satılan" numberData={`${currentMonthSalesCount} Adet - ${new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(currentMonthSales)}`} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplu Satış Miktar" numberData={new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(totalBulk)} />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Widgets type="sm" title="Toplu Satışdan Kaydolan" numberData={statics?.filter?.(i => i.Payment?.is_bulk && i.price === 0)?.length} />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Pie statics={statics.filter(s => !s.Payment.is_bulk)} />
            </ChartWrapper>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Bar statics={statics.filter(s => !s.Payment.is_bulk)} />
            </ChartWrapper>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Table
              data={statics || []}
              columns={columns || []}
              id="reportsTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: false,
                },
                filter: {
                  show: true,
                  filters: [],
                  // onChange: (state, value, text, type) => console.log(state, value, text, type),
                  // drawerChange: () => setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={row => console.log(row)}
              // rowClick={() => window.location.replace('/SuggestionDetail')}
            />
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChartWrapper height="500px">
              <Line />
            </ChartWrapper>
          </Grid> */}
        </Grid>
      </PageWrapper>
    )
  );
}

export default DashboardHook;
