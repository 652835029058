import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Dialog,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  CustomButton,
  CustomDialog,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

export default function NewWord(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [name, setName] = useState('');
  const [meaning, setMeaning] = useState('');
  const [reading, setReading] = useState('');
  const [similar, setSimilar] = useState('');
  const [langId, setLangId] = useState('');
  const [category, setCategory] = useState('');
  const [text, setText] = useState('');

  const [image, setImage] = useState('');
  const [sound, setSound] = useState('');
  const [soundSimilar, setSoundSimilar] = useState('');

  const [example, setExample] = useState('');
  const [translate, setTranslate] = useState('');
  const [exampleSound, setExampleSound] = useState('');

  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [exampleDialogOpen, setExampleDialogOpen] = useState(false);


  const dataExample = [
    {
      example,
      translate,
      voice_url: 'exampleSound',
    },
  ];

  const datas = {
    name,
    meaning,
    reading,
    similar,
    lang_id: parseInt(langId, 10),
    text,
    image_url: 'string',
    voice_reading_url: 'string',
    voice_memory_url: 'string',
    category_id: category,
    WordExamples: dataExample,
  };

  const files = [
    image,
    sound,
    soundSimilar,
  ];

  const allLanguages = useSelector(state1 => state1.allLanguages);

  const dispatch = useDispatch();
  const createWord = useCallback(() => dispatch(thunks.createWord(files, datas)), [dispatch, files, datas]);
  const editWord = useCallback(() => dispatch(thunks.editWord(datas, props.selectedWordId)), [dispatch, datas, props.selectedWordId]);

  const createWordExample = useCallback(() => dispatch(thunks.createWordExample(dataExample)), [dispatch, dataExample]);
  const editWordExample = useCallback(() => dispatch(thunks.editWord(dataExample, props.selectedWordId)), [dispatch, dataExample, props.selectedWordId]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  // useEffect(() => {
  //   if (!allLanguages?.length > 0) {
  //     dispatch(thunks.getLanguages());
  //   }
  // }, [dispatch, allLanguages]);

  // {
  //   "name": "string",
  //   "meaning": "string",
  //   "reading": "string",
  //   "similar": "string",
  //   "lang_id": 0,
  //   "text": "string",
  //   "image_url": "string",
  //   "voice_reading_url": "string",
  //   "voice_memory_url": "string"
  // }

  useEffect(() => {
    if (!props.edit && props.activeLangId) {
      setLangId(props.activeLangId);
    }
    if (!props.edit && props.activeCatId) {
      setCategory(props.activeCatId);
    }
    if (!props.edit && props.activeCatSubId) {
      setCategory(props.activeCatSubId);
    }
  }, [props.edit, props.activeLangId, props.activeCatId, props.activeCatSubId]);

  useEffect(() => {
    if (props.edit && !name && !meaning && !reading && !similar && !langId && !text && !image && !sound && !soundSimilar) {
      setName(props.wordData?.name);
      setMeaning(props.wordData?.meaning);
      setReading(props.wordData?.reading);
      setSimilar(props.wordData?.similar);
      setLangId(props.wordData?.lang_id);
      setText(props.wordData?.text);
      setImage(props.wordData?.image_url);
      setSound(props.wordData?.voice_reading_url);
      setSoundSimilar(props.wordData?.voice_memory_url);
      setCategory(props.activeCatId);
    }
  }, [props.wordData, props.edit]);

  function saveFunc() {
    if (name && meaning && reading && similar && langId && text && image && sound && soundSimilar && category) {
      if (openInfoModal) {
        if (props.edit) {
          editWord();
        } else {
          createWord();
        }
        props.onClose();
      }
      setOpenInfoModal(true);
    } else {
      // dispatch(setToast(true, 'Alanları doldurun!', 'warning'));
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (e.key === 'Enter') {
      saveFunc(true);
    }
  }

  function cancelFiles() {
    if (!props.edit) {
      setImage('');
      setSound('');
      setSoundSimilar('');
    }
    setFileDialogOpen(false);
  }

  function saveExample() {
    if (!props.edit) {
      createWordExample();
    } else {
      editWordExample();
    }
    setFileDialogOpen(false);
  }

  function cancelExample() {
    if (!props.edit) {
      setExample('');
      setTranslate('');
      setExampleSound('');
    }
    setExampleDialogOpen(false);
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
          <div>
            <div className="header">
              <h1>{props.edit ? 'Kelimeyi Düzenle' : 'Yeni Kelime Ekle'}</h1>
              <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <TextField
                  label="Kelime İsmi"
                  fullWidth
                  variant="standard"
                  value={name}
                  autoFocus={!props.edit}
                  onChange={e => setName(e.target.value)}
                />
                <TextField
                  label="Anlamı"
                  fullWidth
                  variant="standard"
                  value={meaning}
                  onChange={e => setMeaning(e.target.value)}
                />
                <TextField
                  label="Okunuşu"
                  fullWidth
                  variant="standard"
                  value={reading}
                  onChange={e => setReading(e.target.value)}
                />
                <TextField
                  label="Benzer"
                  fullWidth
                  variant="standard"
                  value={similar}
                  onChange={e => setSimilar(e.target.value)}
                />
                <TextField
                  label="Metin"
                  fullWidth
                  variant="standard"
                  multiline
                  maxRows={4}
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Dil"
                  value={langId}
                  disabled
                  // onChange={e => setLangId(e.target.value)}
                >
                  {allLanguages?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Kategori(Ana)"
                  // value={category}
                  value={props.activeCatId}
                  // onChange={e => setCategory(e.target.value)}
                  disabled
                >
                  {props.categoryData?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Kategori(Alt)"
                  value={category}
                  // onChange={e => setCategory(e.target.value)}
                  disabled
                >
                  {props.categorySubData?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="itemWrapper">
                  <p>{(image && sound && soundSimilar) ? 'Dosya Seçildi' : 'Dosya Seçilmedi'}</p>
                  <CustomButton purple onClick={() => setFileDialogOpen(true)}>Seç</CustomButton>
                </div>
                <div className="itemWrapper">
                  <p>{(example && translate && exampleSound) ? 'Örnek Seçildi' : 'Örnek Seçilmedi'}</p>
                  <CustomButton purple onClick={() => setExampleDialogOpen(true)}>Seç</CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton onClick={() => props.onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
            {
              openInfoModal && (
                <Info
                  open={openInfoModal}
                  type="Warning"
                  title={props.edit ? 'Kelime Düzenleniyor' : 'Yeni Kelime Ekleniyor'}
                  text="Kelimeler kısmından tekrar düzenleyebilirsiniz."
                  cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                  confirmButton={(<CustomButton purple onClick={() => { saveFunc(); setOpenInfoModal(false); }}>Tamam</CustomButton>)}
                />
              )
            }
          </div>
        </CustomFade>
      </Modal>
      {
        fileDialogOpen && (
          <Dialog
            open={fileDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Dosya Seç</h2>
              <div className="content">
                <div className="fileInputWrapper">
                  <TextField
                    label="Resim "
                    fullWidth
                    variant="standard"
                    value={image?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {image?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `image.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setImage(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="fileInputWrapper">
                  <TextField
                    label="Okunuş Sesi"
                    fullWidth
                    variant="standard"
                    value={sound?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {sound?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `memory.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setSound(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="fileInputWrapper">
                  <TextField
                    label="Benzer Sesi"
                    fullWidth
                    variant="standard"
                    value={soundSimilar?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setSoundSimilar(e.target.value)}
                  />
                  <CustomButton component="label">
                    {soundSimilar?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `reading.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setSoundSimilar(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton purple onClick={() => cancelFiles()}>Vazgeç</CustomButton>
                  <CustomButton purple onClick={() => setFileDialogOpen(false)}>Kaydet</CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
      {
        exampleDialogOpen && (
          <Dialog
            open={exampleDialogOpen}
            // onClose={() => setLangDialogOpen(false)}
          >
            <CustomDialog>
              <h2>Örnek Seç</h2>
              <div className="content">
                <TextField
                  label="Örnek"
                  fullWidth
                  variant="standard"
                  value={example}
                  onChange={e => setExample(e.target.value)}
                />
                <TextField
                  label="Çevirisi"
                  fullWidth
                  variant="standard"
                  value={translate}
                  onChange={e => setTranslate(e.target.value)}
                />
                <div className="fileInputWrapper">
                  <TextField
                    label="Örnek Sesi"
                    fullWidth
                    variant="standard"
                    value={exampleSound?.name || ''}
                    disabled
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={e => setExampleSound(e.target.value)}
                  />
                  <CustomButton component="label">
                    {exampleSound?.name ? 'Eklendi' : 'Ekle'}
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const blob = file && file?.slice(0, file.size, file.type);
                        const newFile = file && new File([blob], `reading.${file?.name?.split('.')?.pop()}`, { type: file.type });
                        setExampleSound(newFile);
                      }}
                    />
                  </CustomButton>
                </div>
                <div className="buttonWrapper">
                  <CustomButton purple onClick={() => cancelExample()}>Vazgeç</CustomButton>
                  <CustomButton purple onClick={() => saveExample()}>Kaydet</CustomButton>
                </div>
              </div>
            </CustomDialog>
          </Dialog>
        )
      }
    </>
  );
}

NewWord.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWordId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  wordData: PropTypes.object,
  categoryData: PropTypes.array.isRequired,
  categorySubData: PropTypes.array.isRequired,
  activeLangId: PropTypes.number.isRequired,
  activeCatId: PropTypes.number.isRequired,
  activeCatSubId: PropTypes.number.isRequired,
};

NewWord.defaultProps = {
  edit: false,
  wordData: {},
};
