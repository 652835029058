import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Input,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { tr } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Info from './Info';
import * as thunks from '../../store/thunks';
import { setToast } from '../../store/actions';

// const invalid = ['+', '-', ',', '.'];

export default function NewCoupon(props) {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedPackage, setSelectedPackage] = useState(null);
  const allPackage = useSelector(state1 => state1.allPackage);

  const data = {
    name,
    code,
    start_date: startDate,
    end_date: endDate,
    discount,
    package_id: selectedPackage,
  };

  const dispatch = useDispatch();
  const createCoupon = useCallback(() => dispatch(thunks.createCoupon(data)), [dispatch, data]);
  const editCoupon = useCallback(() => dispatch(thunks.editCoupon(data, props.selectedCouponId && props.selectedCouponId)), [dispatch, data, props.selectedCouponId]);
  const showToast = (msg, type) => dispatch(setToast(true, msg, type));

  useEffect(() => {
    if (props.edit && props.couponData && !name && !code) {
      setName(props.couponData?.name);
      setCode(props.couponData?.code);
      setDiscount(props.couponData?.discount);
      setSelectedPackage(props.couponData?.package_id);
      setStartDate(props.couponData?.start_date);
      setEndDate(props.couponData?.end_date);
    }
  }, [props.couponData, props.edit]);

  function saveFunc() {
    if (name && code && discount && startDate && endDate) {
      if (openInfoModal) {
        if (props.edit) {
          editCoupon();
          setOpenInfoModal(false);
          props.onClose();
        } else {
          createCoupon();
          setOpenInfoModal(false);
          props.onClose();
        }
      } else {
        setOpenInfoModal(true);
      }
    } else {
      showToast('Alanları doldurun!', 'warning');
    }
  }

  function enterInput(e) {
    if (name && code && e.key === 'Enter') {
      saveFunc();
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in={props.open} onKeyDown={e => enterInput(e)}>
        <div>
          <div className="header">
            <h1>{props.edit ? 'Kuponu Düzenle' : 'Yeni Kupon Ekle'}</h1>
            <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
          </div>
          <div className="content">
            <div className="form">
              <TextField
                label="Kupon İsmi"
                fullWidth
                variant="standard"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                label="Kupon Kodu"
                fullWidth
                variant="standard"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
              <Input
                label="İndirim Oranı"
                fullWidth
                inputProps={{
                  inputMode: 'numeric', pattern: '[0-9]', max: '100', min: '0',
                }}
                value={discount}
                onChange={(e) => { if ((e.target.value > 0 || e.target.value === '') && e.target.value < 101) setDiscount(e.target.value); }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
              <TextField
                select
                fullWidth
                variant="standard"
                label="Paket Seçimi"
                value={selectedPackage}
                onChange={(e) => {
                  setSelectedPackage(e.target.value);
                }}
              >
                {allPackage?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="dateWrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Başlangıç Tarihi"
                    value={startDate}
                    onChange={e => setStartDate(e)}
                    autoOk
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    // style={{ paddingTop: 10 }}
                    InputLabelProps={{ focused: true, shrink: true }}
                    disablePast
                    // fullWidth
                    invalidDateMessage="Geçerli bir tarih giriniz"
                    // minDateMessage="Bugünden itibaren tarih seçiniz"
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Bitiş Tarihi"
                    value={endDate}
                    onChange={e => setEndDate(e)}
                    autoOk
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    // style={{ paddingTop: 10 }}
                    minDate={startDate}
                    InputLabelProps={{ focused: true, shrink: true }}
                    // fullWidth
                    disablePast
                    invalidDateMessage="Geçerli bir tarih giriniz"
                    minDateMessage="Başlangıç tarihinden sonraki bir tarih seçiniz"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="buttonWrapper">
                <CustomButton onClick={() => props.onClose()} lined>
                  Vazgeç
                </CustomButton>
                <CustomButton purple onClick={() => saveFunc()}>
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
          {
            openInfoModal && (
              <Info
                open={openInfoModal}
                type="Warning"
                title={props.edit ? 'Kupon Düzenleniyor' : 'Yeni Kupon Ekleniyor'}
                text="Kuponlar kısmından tekrar düzenleyebilirsiniz."
                cancelButton={(<CustomButton lined onClick={() => setOpenInfoModal(false)}>Vazgeç</CustomButton>)}
                confirmButton={(<CustomButton purple onClick={() => saveFunc()}>Tamam</CustomButton>)}
              />
            )
          }
        </div>
      </CustomFade>
    </Modal>
  );
}

NewCoupon.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCouponId: PropTypes.number.isRequired,
  edit: PropTypes.bool,
  couponData: PropTypes.object,
};

NewCoupon.defaultProps = {
  edit: false,
  couponData: {},
};
