export const SET_TOAST = 'SET_TOAST';

export const DO_LOGIN = 'DO_LOGIN';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const APP_CONFIG = 'APP_CONFIG';

export const REGISTER = 'REGISTER';

export const GET_STATICS = 'GET_STATICS';
export const SET_STATICS = 'SET_STATICS';

export const GET_ALL_LANGUAGES = 'GET_ALL_LANGUAGES';
export const SET_ALL_LANGUAGES = 'SET_ALL_LANGUAGES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const EDIT_LANGUAGE = 'EDIT_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';

export const CREATE_WORD = 'CREATE_WORD';
export const GET_ALL_WORD = 'GET_ALL_WORD';
export const SET_ALL_WORD = 'SET_ALL_WORD';
export const SET_WORD = 'SET_WORD';
export const SET_WORD_DETAIL = 'SET_WORD_DETAIL';
export const EDIT_WORD = 'EDIT_WORD';
export const DELETE_WORD = 'DELETE_WORD';
export const EDIT_WORD_EXAMPLE = 'EDIT_WORD_EXAMPLE';
export const DELETE_WORD_EXAMPLE = 'DELETE_WORD_EXAMPLE';
export const CREATE_WORD_EXAMPLE = 'CREATE_WORD_EXAMPLE';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';
export const SET_CATEGORY = 'SET_CATEGORY';
export const GET_CATEGORYSUB = 'GET_CATEGORYSUB';
export const SET_CATEGORYSUB = 'SET_CATEGORYSUB';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const SET_USER = 'SET_USER';
export const GET_ALL_USER = 'GET_ALL_USER';
export const SET_ALL_USER = 'SET_ALL_USER';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';

export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const GET_ALL_PACKAGE = 'GET_ALL_PACKAGE';
export const SET_ALL_PACKAGE = 'SET_ALL_PACKAGE';
export const GET_PACKAGE = 'GET_PACKAGE';
export const SET_PACKAGE = 'SET_PACKAGE';
export const EDIT_PACKAGE = 'EDIT_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';

export const CREATE_COUPON = 'CREATE_COUPON';
export const GET_ALL_COUPON = 'GET_ALL_COUPON';
export const SET_ALL_COUPON = 'SET_ALL_COUPON';
export const GET_COUPON = 'GET_COUPON';
export const SET_COUPON = 'SET_COUPON';
export const EDIT_COUPON = 'EDIT_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';

export const CREATE_BLOG = 'CREATE_BLOG';
export const GET_BLOGS = 'GET_BLOGS';
export const SET_BLOGS = 'SET_BLOGS';
export const UPDATE_BLOGS = 'UPDATE_BLOGS';

export const SET_LAST_UPLOAD_FILE = 'SET_LAST_UPLOAD_FILE';

export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_USER_PAYMENT_LIST = 'SET_USER_PAYMENT_LIST';
export const SET_BULK_SALES = 'SET_BULK_SALES';
export const SET_WORD_REPORTS = 'SET_WORD_REPORTS';
