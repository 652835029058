/* eslint-disable react/prop-types */
import {
  Backdrop, MenuItem, Modal, TextField,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { CustomButton, CustomFade, CustomIconButton } from '../../assets/styled';
import { payment_status } from '../../commons/constants';
import * as thunks from '../../store/thunks';

function ChangePaymentStatus(props) {
  const [selectedType, setSelectedType] = useState(null);
  const [warningOpen, setWarningOpen] = useState(false);

  const dispatch = useDispatch();
  const changePaymentStatu = useCallback((id, statu) => dispatch(thunks.changePaymentStatus(id, statu, props.listParams)), [dispatch]);

  const saveFunc = (checked) => {
    if (selectedType) {
      if (selectedType === 'COMPLETED' && !checked && !props.data?.is_bulk) {
        setWarningOpen(true);
        return;
      }
      changePaymentStatu(props.data.id, selectedType);
      setWarningOpen(false);
      props.onClose();
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={props.open}>
          <div>
            <div className="header">
              <h1>Ödeme Durumu Değiştir</h1>
              <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              <div className="form">
                <TextField
                  select
                  fullWidth
                  variant="standard"
                  label="Ödeme Durumu"
                  value={selectedType}
                  onChange={e => setSelectedType(e.target.value)}
                >
                  {payment_status?.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="buttonWrapper">
                  <CustomButton onClick={() => props.onClose()} lined>
                    Vazgeç
                  </CustomButton>
                  <CustomButton purple onClick={() => saveFunc()}>
                    Kaydet
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        warningOpen && (
          <Modal
            open={warningOpen}
            onClose={() => setWarningOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <CustomFade in={props.open}>
              <div>
                <div className="header">
                  <h1>DİKKAT!</h1>
                  <CustomIconButton onClick={() => setWarningOpen(false)}><Close /></CustomIconButton>
                </div>
                <div className="content">
                  <div className="form" style={{ color: 'white' }}>
                    <p>Ödeme durumu <strong><u>BAŞARILI</u></strong> olarak değiştiriyorsunuz.</p>
                    <p>Eğer onaylarsanız kullanıcıya paket tanımlanacaktır.</p>
                    <p>Onaylıyor musunuz?</p>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => setWarningOpen(false)} lined>
                        Vazgeç
                      </CustomButton>
                      <CustomButton purple onClick={() => saveFunc(true)}>
                        Ödemeyi Onayla
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </CustomFade>
          </Modal>
        )
      }
    </>
  );
}

export default ChangePaymentStatus;
