import * as constants from './constants';

// SET_SHOW_TOAST
export const setToast = (value, message, variant) => (dispatch) => {
  dispatch({
    type: constants.SET_TOAST, value, message, variant,
  });
};

export const doLogin = (mail, password) => (dispatch) => {
  dispatch({ type: constants.DO_LOGIN, mail, password });
};

export const setActiveUser = data => (dispatch) => {
  dispatch({ type: constants.SET_ACTIVE_USER, data });
};

export const setAppConfig = (key, value) => (dispatch) => {
  dispatch({ type: constants.APP_CONFIG, key, value });
};

export const doRegister = data => (dispatch) => {
  dispatch({ type: constants.REGISTER, data });
};

export const getLanguages = id => (dispatch) => {
  dispatch({ type: constants.GET_ALL_LANGUAGES, id });
};

export const setAllLanguages = data => (dispatch) => {
  dispatch({ type: constants.SET_ALL_LANGUAGES, data });
};

export const setLanguage = data => (dispatch) => {
  dispatch({ type: constants.SET_LANGUAGE, data });
};

export const addLanguage = (name, description, id) => (dispatch) => {
  // dispatch({
  if (id) {
    dispatch({
      type: constants.EDIT_LANGUAGE, name, description, id,
    });
  } else {
    dispatch({
      type: constants.CREATE_LANGUAGE, name, description,
    });
  }
  // });
};

export const deleteLanguage = id => (dispatch) => {
  dispatch({ type: constants.DELETE_LANGUAGE, id });
};

export const createWord = (files, data) => (dispatch) => {
  dispatch({ type: constants.CREATE_WORD, files, data });
};

export const getWord = id => (dispatch) => {
  dispatch({ type: constants.GET_ALL_WORD, id });
};

export const setAllWords = data => (dispatch) => {
  dispatch({ type: constants.SET_ALL_WORD, data });
};

export const setWord = data => (dispatch) => {
  dispatch({ type: constants.SET_WORD, data });
};

export const setWordDetail = data => (dispatch) => {
  dispatch({ type: constants.SET_WORD_DETAIL, data });
};

export const editWord = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_WORD, data, id });
};

export const deleteWord = (id, getWordId) => (dispatch) => {
  dispatch({ type: constants.DELETE_WORD, id, getWordId });
};

export const createWordExample = data => (dispatch) => {
  dispatch({ type: constants.CREATE_WORD_EXAMPLE, data });
};

export const editWordExample = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_WORD_EXAMPLE, data, id });
};

export const deleteWordExample = id => (dispatch) => {
  dispatch({ type: constants.DELETE_WORD_EXAMPLE, id });
};

export const createCategory = data => (dispatch) => {
  dispatch({ type: constants.CREATE_CATEGORY, data });
};

export const getCategory = id => (dispatch) => {
  dispatch({ type: constants.GET_CATEGORY, id });
};

export const setCategory = data => (dispatch) => {
  dispatch({ type: constants.SET_CATEGORY, data });
};

export const getCategorySub = id => (dispatch) => {
  dispatch({ type: constants.GET_CATEGORYSUB, id });
};

export const setCategorySub = data => (dispatch) => {
  dispatch({ type: constants.SET_CATEGORYSUB, data });
};

export const editCategory = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_CATEGORY, data, id });
};

export const deleteCategory = (id, langId) => (dispatch) => {
  dispatch({ type: constants.DELETE_CATEGORY, id, langId });
};

export const createUser = files => (dispatch) => {
  dispatch({ type: constants.CREATE_USER, files });
};

export const getUser = id => (dispatch) => {
  dispatch({ type: constants.GET_ALL_USER, id });
};

export const setAllUser = data => (dispatch) => {
  dispatch({ type: constants.SET_ALL_USER, data });
};

export const setUser = data => (dispatch) => {
  dispatch({ type: constants.SET_USER, data });
};

export const editUser = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_USER, data, id });
};

export const deleteUser = id => (dispatch) => {
  dispatch({ type: constants.DELETE_USER, id });
};

export const createPackage = data => (dispatch) => {
  dispatch({ type: constants.CREATE_PACKAGE, data });
};

export const getPackage = id => (dispatch) => {
  dispatch({ type: constants.GET_PACKAGE, id });
};

export const setAllPackage = data => (dispatch) => {
  dispatch({ type: constants.SET_ALL_PACKAGE, data });
};

export const setPackage = data => (dispatch) => {
  dispatch({ type: constants.SET_PACKAGE, data });
};

export const editPackage = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_PACKAGE, data, id });
};

export const deletePackage = id => (dispatch) => {
  dispatch({ type: constants.DELETE_PACKAGE, id });
};

export const getCoupon = id => (dispatch) => {
  dispatch({ type: constants.GET_COUPON, id });
};

export const setAllCoupon = data => (dispatch) => {
  dispatch({ type: constants.SET_ALL_COUPON, data });
};

export const setCoupon = data => (dispatch) => {
  dispatch({ type: constants.SET_COUPON, data });
};

export const editCoupon = (data, id) => (dispatch) => {
  dispatch({ type: constants.EDIT_COUPON, data, id });
};

export const deleteCoupon = id => (dispatch) => {
  dispatch({ type: constants.DELETE_COUPON, id });
};

export const getStatics = () => (dispatch) => {
  dispatch({ type: constants.GET_STATICS });
};

export const setStatics = data => (dispatch) => {
  dispatch({ type: constants.SET_STATICS, data });
};

export const createBlog = data => (dispatch) => {
  dispatch({ type: constants.CREATE_BLOG, data });
};

export const getBlogs = () => (dispatch) => {
  dispatch({ type: constants.GET_BLOGS });
};

export const setBlogs = data => (dispatch) => {
  dispatch({ type: constants.SET_BLOGS, data });
};

export const updateBlog = (id, data) => (dispatch) => {
  dispatch({ type: constants.UPDATE_BLOGS, id, data });
};

export const setLastUplaodFile = url => (dispatch) => {
  dispatch({ type: constants.SET_LAST_UPLOAD_FILE, url });
};

export const setPaymentList = data => (dispatch) => {
  dispatch({ type: constants.SET_PAYMENT_LIST, data });
};

export const setUserPaymentList = data => (dispatch) => {
  dispatch({ type: constants.SET_USER_PAYMENT_LIST, data });
};

export const setBulkSales = data => (dispatch) => {
  dispatch({ type: constants.SET_BULK_SALES, data });
};

export const setWordReports = data => (dispatch) => {
  dispatch({ type: constants.SET_WORD_REPORTS, data });
};
